import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { checkPastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import router from '@/router';

export default class StockGameListViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.boardData = {
      title:'모의투자 목록',
      drawDataList: [
        {
          title:'라운드',
          width:'60px',
          value:'round',
        },
        {
          title:'상태',
          width:'66px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'common_status'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'common_status'
            },
            badgeFill: false
          },
        },
        {
          title:'UID',
          width:'190px',
          value:'uid',
          isAlignLeft: true,
        },
        {
          title:'접수 기한',
          width:'112px',
          value:'',
          dateRangeValue:{
            startValue:'receiptStartAt',
            endValue:'receiptEndAt',
            betweenText:' ~ ',
            isBetweenDateToBlue:true,
            isEndPastDateToRed:true,
          },
          isAlignLeft: true
        },
        {
          title:'레이스 기한',
          width:'112px',
          value:'',
          dateRangeValue:{
            startValue:'raceStartAt',
            endValue:'raceEndAt',
            betweenText:' ~ ',
            isBetweenDateToBlue:true,
            isEndPastDateToRed:true
          },
          isAlignLeft: true
        },
        {
          title:'모의투자금',
          width:'80px',
          value:'feeAmount',
          filter: {
            name:'convertNumberToComma',
            value:''
          },
        },
        {
          title:'리워드 이미지',
          width:'114px',
          value:'rewardImgUrl',
          img:{
            size:'contain',
            width:'96.8px',
            height:'40px',
            position:{
              x:'center',
              y:'center',
            }
          }
        },
        {
          title:'리워드',
          width:'',
          value:'reward',
          isAlignLeft: true,
        },
        {
          title:'레이스 타입',
          width:'94px',
          value:'raceType',
          filter: {
            name:'convertIdToText',
            value:'stock_game_race_type'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'stock_game_race_type'
            },
          },
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        rowIdValue: 'uid'
      },
    }
  }
  init(){
    this.getGameList();
  }
  onSearch(){
    this.getGameList();
  }
  onClickGameRegister(){
    router.push({ name: 'STOCK_GAME_REGISTER' });
  }
  onClickRow(rowId){
    router.push({ name: 'STOCK_GAME_MODIFY', params: { id : rowId } });
  }

  getGameList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.STOCK_GAME_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.body.map(item => {
        item.disabled = Boolean(checkPastDate( item.raceEndAt, 'yesIsPast' )) || item.status === 'UNREGISTERED'
        return item
      });
      this.paginationData.totalCount = resultData.pagination.total_elements;
      this.paginationData.totalPage = resultData.pagination.total_page;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}