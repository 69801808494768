<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="raceUid => viewModel.onClickRow(raceUid)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="새 레이스 추가"
          @onClickBtn="viewModel.onClickGameRegister()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import StockGameListViewModel from '@/views/stock/game/viewModel/StockGameListViewModel';

export default {
  name: 'StockGameList',
  components: {
    PageWithLayout,
    Board,
    Button,
    IconSvg
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new StockGameListViewModel(),
    }
  }
}
</script>

<style scoped>
</style>
